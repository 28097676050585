import Head from "next/head";

import Typewriter from "../components/Typewriter";
import styles from "../styles/Home.module.scss";

interface Product {
  key: string;
  name: string;
  description: string;
  url: string;
}

const PRODUCTS: Product[] = [
  {
    key: "campobserver",
    name: "Camp Observer",
    description: "Quickly find and reserve camp sites",
    url: "https://campobserver.com?utm_source=modernovation_landing",
  },
];

export default function Home() {
  return (
    <>
      <video
        id="background-video"
        poster="background.jpg"
        autoPlay
        loop
        muted
        playsInline>
        <source src="background.mp4" />
      </video>

      <div className={styles.container}>
        <Head>
          <title>Modernovation: Software solutions</title>
          <meta name="description" content="Modernovation software" />
          <link rel="icon" href="/favicon.ico" />
        </Head>

        <main className={styles.main}>
          <h1 className={styles.title}>Modernovation</h1>

          <div className={styles.overview}>
            <div className={styles.description}>
              <Typewriter text="We build awesome SaaS products to power your business" />
            </div>

            <div className={styles.subtext}>
              Need help creating your next big idea?
            </div>

            <button
              className={styles.cta}
              onClick={event => {
                event.preventDefault();
                const company = "modernovation";
                window.location.href =
                  "mailto:" +
                  "solutions@" +
                  company +
                  ".com?subject=I'm interested in...";
              }}>
              Get in touch
            </button>
          </div>

          <div className={styles.productList}>
            <h1>Featured Products</h1>

            {PRODUCTS.map(product => (
              <div
                key={product.key}
                className={styles.product}
                onClick={event => {
                  event.preventDefault();
                  window.open(product.url, "_blank");
                }}>
                <div className={styles.productLogo}>
                  <img
                    src={`/products/${product.key}/logo.png`}
                    alt={`${product.name} logo`}
                  />
                </div>
                <div className={styles.productText}>
                  <div className={styles.productName}>
                    <a href={product.url}>{product.name}</a>
                  </div>
                  <div className={styles.productDescription}>
                    {product.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </main>
      </div>
    </>
  );
}
